import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { AppUrls } from '../enums/app-urls.enum';
import { FormDataService } from '../services/form-data.service';

export const travelSelectionGuard: CanMatchFn = (route, segments) => {
  const formService = inject(FormDataService);
  const router = inject(Router);

  if (formService.isFormValid()) {
    return true;
  }
  return router.createUrlTree([AppUrls.HOME]);
};
