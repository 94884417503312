import { Routes } from '@angular/router';
import { travelProtectionRoutes } from '@shared/core/routes/travel-protection.routes';
import { travelSelectionGuard } from '@shared/core/guards/travel-selection.guard';
import { AppUrls } from './shared/core/enums/app-urls.enum';

export const routes: Routes = [
  {
    path: AppUrls.HOME,
    loadComponent: () => import('@pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: AppUrls.TRAVEL_PROTECTION,
    loadChildren: () => travelProtectionRoutes,
    canMatch: [travelSelectionGuard],
  },
  {
    path: `${AppUrls.LINK}/:id`,
    loadComponent: () => import('@pages/link/link.component').then((m) => m.LinkComponent),
  },
  {
    path: AppUrls.FAQ,
    loadComponent: () => import('@pages/faq/faq.component').then((m) => m.FaqComponent),
  },
];
