import { Routes } from '@angular/router';
import { AppUrls } from '../enums/app-urls.enum';

export const travelProtectionRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('@pages/travel-protection/travel-protection.component').then(
        (m) => m.TravelProtectionComponent,
      ),
  },
  {
    path: AppUrls.PLAN_SELECTION,
    loadComponent: () =>
      import(
        '@pages/travel-protection/components/plan-selection/plan-selection.component'
      ).then((m) => m.PlanSelectionComponent),
  },
  {
    path: AppUrls.TRAVELER_INFO,
    loadComponent: () =>
      import(
        '@pages/travel-protection/components/travel-info/travel-info.component'
      ).then((m) => m.TravelInfoComponent),
  },
  {
    path: AppUrls.ADDITIONAL_COVERAGE,
    loadComponent: () =>
      import(
        '@pages/travel-protection/components/additional-coverage/additional-coverage.component'
      ).then((m) => m.AdditionalCoverageComponent),
  },
  {
    path: AppUrls.PAYMENT_INFO,
    loadComponent: () =>
      import(
        '@pages/travel-protection/components/payment-info/payment-info.component'
      ).then((m) => m.PaymentInfoComponent),
  },
  {
    path: AppUrls.PROTECTED_SUCCESS,
    loadComponent: () =>
      import(
        '@pages/travel-protection/components/protected-success/protected-success.component'
      ).then((m) => m.ProtectedSuccessComponent),
  },
];
